export const START = 'start';
export const LAUNCH = 'launch';
export const ACCELERATE = 'accelerate';
export const ACCELERATE_YEARLY = 'accelerate_yearly';
export const EXPERT = 'expert';
export const EXPERT_5 = 'Reseller_5';
export const FREE = 'free';
export const PREMIUM = 'premium';
export const EXPERT_NEW = 'expert_new';
export const SOMETHING = 'something';
export const SOMETHING_YEARLY = 'something_yearly';

export const DIY = 'diy';
export const DIY_YEARLY = 'diy_yearly';

export const DELUX = 'deluxe';
export const DELUX_YEARLY = 'deluxe_yearly';
export const DIY_ADDON = 'addon';
export const STG_NEW_PRICING_START_DATE = 1575889977000; //05-11-2019
export const LIVE_NEW_PRICING_START_DATE = 1575990805918; //09-12-2019
export const ADDON_MONTHLY_PRICE = 1;
export const ADDON_YEARLY_PRICE = 12;
export const TIER_1_PLAN_ID = 'beezer_tier1';
export const TIER_2_PLAN_ID = 'beezer_tier2';
export const TIER_3_PLAN_ID = 'beezer_tier3';
export const TIER_4_PLAN_ID = 'beezer_tier4';
export const TIER_5_PLAN_ID = 'beezer_tier5';
export const APP_SUMO_EXPERT_PLANS = [
  TIER_2_PLAN_ID,
  TIER_3_PLAN_ID,
  TIER_4_PLAN_ID,
  TIER_5_PLAN_ID,
];

export const APP_SUMO_PLANS = [TIER_1_PLAN_ID, ...APP_SUMO_EXPERT_PLANS];

export const PLANS_WITH_ADDONS = [DIY, LAUNCH];

export const CUSTOM_MARKETPLACE_APPS = ['customarketplace', 'sellmygym'];

export const PLANS_WITH_ADMIN_ACCESS = [
  EXPERT_NEW,
  EXPERT_5,
  ...APP_SUMO_EXPERT_PLANS,
];
export const DEFAULT_WHATSAPP_CREDIT = 40;
export const componentNewNames = {
  Feedback: 'Video Feedback',
  PDFViewer: 'PDF Library',
  CustomDomain: 'Custom Domain',
  Woocommerce: 'WooCommerce',
};

export const DIARY_ADDON_PLAN = {
  monthly: 8,
  yearly: 86.4,
  name: 'diary_addon_plan',
  plan: 'deluxe',
  subscriptionPlan: 'addon',
  usd: {
    price: 11,
    year_price: 118.8,
  },
  eur: {
    price: 10,
    year_price: 108,
  },
  aud: {
    price: 16,
    year_price: 172.8,
  },
  nzd: {
    price: 17,
    year_price: 183.6,
  },
  cad: {
    price: 14,
    year_price: 151.2,
  },
  zar: {
    price: 193,
    year_price: 2085,
  },
};
