import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import moment from 'moment';
import IconButton from 'material-ui/IconButton';
import { FormattedNumber } from 'react-intl';
import { Trans, withTranslation } from 'react-i18next';

import DiyPlansInfo from './components/DiyPlansInfo';
import { Close } from '../../../../app/components/icons';
import Badge from '../../components/badge';
import Thing from '../../components/thing';
import {
  plans,
  plansWixWeebly,
  pricing,
  oldPlansMapping,
} from '../../../../plans';
import { getAppUrl } from '../../../../utils/appDetails';
import { lowerCase } from '../../../../utils/string';
import { RoundedThinButton } from '../../../../app/components/buttons';
import { PLAN_COST_DISABLED_ACCOUNTS } from '../../../../config/';
import {
  USER_CLICKED_UPGRADE,
  PLATFORM_EVENTS,
  sendSlackNotification,
} from '../../../../utils';
import AppsumoSubscriptions from './AppsumoSubscriptions';
import './styles.css';

class Subscriptions extends Component {
  static propTypes = {
    apps: PropTypes.object,
    plans: PropTypes.object,
    icons: PropTypes.object,
    organisation: PropTypes.object.isRequired,
    freeTrial: PropTypes.string,
    getSubscriptions: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    onCancelPlan: PropTypes.func.isRequired,
    loadIcon: PropTypes.func.isRequired,
    toggleAccountModal: PropTypes.func.isRequired,
    integrationType: PropTypes.string,
    openWixPlans: PropTypes.func.isRequired,
    accountId: PropTypes.string,
    subscription: PropTypes.string,
    customDomain: PropTypes.object,
    intl: PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }).isRequired,
    getBilling: PropTypes.func.isRequired,
    account: PropTypes.string.isRequired,
  };

  static defaultProps = {
    integrationType: '',
    freeTrial: null,
    apps: {},
    plans: {},
    customDomain: {},
    icons: {},
    accountId: '',
    subscription: null,
  };

  componentDidMount() {
    this.props.getBilling();
  }

  componentWillMount() {
    this.props.getSubscriptions();
    _forEach(this.props.apps, (app, domain) => this.props.loadIcon(domain));
  }

  getPlanTitle = appname => {
    const { freeTrial, organisation } = this.props;
    const { plan, cancelled, paymentType } = this.props.plans[appname];
    const isExpert = organisation && organisation.legacy_flag === 20;
    const is90DaysFreeTrial = organisation && organisation.accountType;
    const freeTrialExpired = freeTrial < new Date().toISOString();
    let subscriptionName = plan;
    if (subscriptionName) {
      if (isExpert) {
        return 'Reseller';
      } else if (cancelled && freeTrialExpired) {
        return 'expired';
      }
      if (oldPlansMapping[subscriptionName]) {
        subscriptionName = oldPlansMapping[subscriptionName];
      }

      //get real title
      const title = plans[subscriptionName]
        ? plans[subscriptionName].title
        : subscriptionName;
      return title;
    }
    if (!freeTrialExpired) {
      if (is90DaysFreeTrial) {
        return '90 day free trial';
      }
      if (this.props.isOn14DaysTrial) {
        return '14 day free trial';
      }
      return '30 day free trial';
    } else if (cancelled && !freeTrial) {
      return 'expired';
    } else if (freeTrial) {
      if (this.props.isOn14DaysTrial) {
        return '14 day free trial';
      }
      return '30 day free trial';
    }

    return 'expired';
  };

  getCost = subscription => {
    let finalSubscription = subscription;
    let currency;
    let cost;
    if (subscription.includes('_yearly')) {
      finalSubscription = finalSubscription.replace('_yearly', '');
      finalSubscription = finalSubscription.replace('2023', '');
      if (finalSubscription.includes('-')) {
        const [sub, cur] = finalSubscription.split('-');
        finalSubscription = sub;
        currency = cur;
      }

      if (currency) cost = plans[finalSubscription][currency].year_price * 12;
      else cost = plans[finalSubscription].year_price * 12;
    } else {
      if (finalSubscription.includes('-')) {
        finalSubscription = finalSubscription.replace('2023', '');
        const [sub, cur] = finalSubscription.split('-');
        finalSubscription = sub;
        currency = cur;
      }
      if (currency) cost = plans[finalSubscription][currency].price;
      else cost = plans[finalSubscription].price;
    }
    return cost;
  };

  getPlanInfo = appname => {
    const { discountText, period, paymentType, id } = this.props.plans[appname];

    const billingPlan =
      this.props.billing && this.props.billing.find(bill => bill.id === id);

    let plan = this.getPlanTitle(appname);
    if (discountText) {
      return <span dangerouslySetInnerHTML={{ __html: discountText }} />;
    }

    let currency;
    if (this.props.subscription && this.props.subscription.includes('-')) {
      const formattedSubscription = this.props.subscription.replace('2023', '');

      currency = formattedSubscription.split('-')[1];
      if (currency.includes('_')) {
        currency = currency.split('_')[0];
      }
    }

    const subCost = this.props.subscription
      ? this.getCost(this.props.subscription)
      : null;

    const realPlan = plan;
    if (plan.includes('deluxe')) plan = 'deluxe';
    if (plan.includes('diy')) plan = 'diy';
    if (plan.includes('something')) plan = 'something';
    if (plan === 'Reseller') plan = 'expert';
    switch (plan) {
      case 'launch':
      case 'expert':
      case 'expert_new':
      case 'deluxe':
      case 'everything':
      case 'Everything':
      case 'deluxe_yearly':
      case 'deluxe_yearly-2023':
      case 'diy':
      case 'something':
      case 'anything':
      case 'Anything':
      case 'accelerate': {
        let cost;
        const { accountId } = this.props;
        cost = paymentType === 'clickbank' ? 250 : 500;
        cost = accountId === '-NwqoHhta_jpApxRM4TR' ? 600 : cost;

        let finalPlan =
          realPlan === 'Anything' ||
          realPlan === 'diy' ||
          realPlan.includes('diy')
            ? 'diy'
            : realPlan === 'Everything' || realPlan === 'deluxe'
            ? 'deluxe'
            : realPlan;

        if (finalPlan === 'Reseller') {
          finalPlan = 'expert';
        }

        if (period !== 'lifetime') {
          if (_get(plans, `${finalPlan}.${currency}`, '')) {
            if (period === 'yearly') {
              cost = parseFloat(
                (
                  _get(plans, `${finalPlan}.${currency}.year_price`, 0) * 12
                ).toFixed(2),
              );
            } else {
              cost = _get(plans, `${finalPlan}.${currency}.price`);
            }
          } else if (paymentType === 'clickbank') {
            cost = this.getClickbankPlanCost(period);
          } else if (
            finalPlan.includes('deluxe_yearly') ||
            (finalPlan === 'expert_new' && period === 'yearly')
          ) {
            cost = _get(plans, `${finalPlan}.year_price`);
          } else if (finalPlan === 'expert' && period === 'yearly') {
            cost = _get(plans, `${finalPlan}.year_price`) * 12;
          } else {
            cost = this.calculatePeriodCost(
              _get(plans, `${finalPlan}.price`),
              period === 'yearly',
            );
          }
        }

        let planName =
          plan === 'expert_new'
            ? 'expert'
            : plan === 'deluxe' ||
              plan === 'deluxe_yearly' ||
              plan === 'deluxe_yearly-2023'
            ? 'Everything'
            : plan;

        planName = planName.includes('diy') ? 'Anything' : planName;

        if (billingPlan) {
          cost = (billingPlan.amount_paid / 100).toFixed(2);
        }

        return PLAN_COST_DISABLED_ACCOUNTS.indexOf(this.props.accountId) >
          -1 ? (
          <span className="plan-name">
            {`${period === 'lifetime' ? 'Lifetime' : planName} Plan`}
          </span>
        ) : (
          <>
            <span className="plan-name">
              {`${
                period === 'lifetime'
                  ? 'Lifetime'
                  : planName.includes('expert')
                  ? 'Reseller'
                  : planName
              } Plan ${period === 'monthly' ? 'Monthly' : 'Yearly'} - `}
              <FormattedNumber
                maximumFractionDigits={2}
                format={this.props.intl.currency}
                currency={currency || 'gbp'}
                value={cost || subCost}
              />{' '}
              {(!currency || currency.toLowerCase() === 'gbp') && (
                <span> +VAT</span>
              )}
            </span>
          </>
        );
      }
      case 'premium': {
        const cost = this.calculatePeriodCost(
          _get(plansWixWeebly, 'premium.price'),
          period === 'yearly',
        );
        return (
          <div>
            {this.isIntegration() ? 'Premium' : 'Everything'} Plan
            {!this.isWix() && (
              <span>
                <span> - </span>
                <FormattedNumber
                  maximumFractionDigits={2}
                  format={this.props.intl.currency}
                  currency={currency || 'gbp'}
                  value={cost || subCost}
                />
              </span>
            )}
          </div>
        );
      }
      case '90 day free trial':
        return '90 Days Free Trial';

      case '14 day free trial':
        return '14 Days Free Trial';

      case '30 day free trial':
        return '30 Days Free Trial';

      case 'start': {
        return 'Free-Plan';
      }

      default:
        return this.isIntegration() ? 'Free plan' : 'Free Trial Period Expired';
    }
  };

  getPlanEndDate = appname => {
    const { t, account } = this.props;
    const { cancelling, current_period_end } = this.props.plans[appname];
    const title = this.getPlanTitle(appname);
    const freeTrial =
      lowerCase(title) === '30 day free trial' ||
      lowerCase(title) === '14 day free trial';

    if (
      title !== 'free' &&
      title !== 'expired' &&
      account &&
      account.ref !== 'dealfuel'
    ) {
      return (
        <div style={{ marginLeft: '10%' }}>
          <small>
            {cancelling ? t('Subscription Cancelled. Ends') : t('Next Renewal')}
            &nbsp;
            {!current_period_end && (
              <span>{moment(this.props.freeTrial).format('DD/MM/YY')}</span>
            )}
            {current_period_end && (
              <span>{moment(current_period_end).format('DD/MM/YY')}</span>
            )}
          </small>
        </div>
      );
    }

    return null;
  };

  calculatePeriodCost = (monthlyCost, yearly) => {
    const { multiplier, comparison } = pricing.yearly;

    //the  yearly Multiplier for the price is calculated by multiplier * comparison
    return yearly
      ? (monthlyCost * multiplier * comparison).toFixed(2)
      : monthlyCost;
  };

  getClickbankPlanCost = period => {
    if (period === 'yearly') {
      return '100';
    }
  };

  isIntegration = () => {
    const { integrationType } = this.props;
    return Boolean(integrationType);
  };

  isWix = () => {
    const { integrationType } = this.props;
    return integrationType === 'Wix';
  };

  handleSubscribe = appname => {
    const { history, openWixPlans, accountId, subscription } = this.props;

    sendSlackNotification({
      appname,
      notificationTitle: USER_CLICKED_UPGRADE,
      account: accountId,
      currentPlan: subscription,
      slackChannel: PLATFORM_EVENTS,
    });

    if (this.isWix()) {
      const plan = 'premium';
      return openWixPlans(appname, plan, history, accountId);
    }
    this.props.history.push(`/${appname}/choose_plan`);
    return this.props.toggleAccountModal();
  };

  handleUnsubscribe = (planName, appname) => {
    const { history, openWixPlans, accountId } = this.props;

    if (this.isWix()) {
      const plan = 'free';
      return openWixPlans(appname, plan, history, accountId);
    }
    return this.props.onCancelPlan(planName, appname, false, null);
  };

  renderApps = () =>
    _map(this.props.apps, (app, domain) => {
      const { t } = this.props;
      const plan = this.props.plans[domain].plan;
      const hasPlan = plan && plan !== 'free';

      return (
        <React.Fragment>
          <Thing key={domain} className="plan">
            <div className="details flex-row">
              <div className="app-icon-container">
                {this.props.icons[domain] && this.props.icons[domain].icon && (
                  <img src={this.props.icons[domain].icon} alt="app icon" />
                )}
              </div>
              <div>
                <h4 className="title">{app.title}</h4>
                {/* <small className='subtitle'>
            app.
            Last Published [date here]
          </small> */}
                <a
                  className="app-link"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={getAppUrl(domain, this.props.customDomain)}
                >
                  {getAppUrl(domain, this.props.customDomain, false)}
                </a>
              </div>
            </div>
            {!this.props.isAppsumoAccount ? (
              <React.Fragment>
                <div className="details flex-row subscription">
                  <Badge
                    label={
                      domain === 'performancesupport' ? (
                        <span style={{ textTransform: 'capitalize' }}>
                          Everything Plan Yearly - £842.40
                        </span>
                      ) : (
                        this.getPlanInfo(domain)
                      )
                    }
                  />
                  {this.props.plans[domain].period !== 'lifetime' &&
                    !this.isWix() &&
                    this.getPlanEndDate(domain)}
                </div>
                <div className="controls">
                  {!hasPlan && (
                    <RoundedThinButton
                      className="success"
                      onClick={() => this.handleSubscribe(domain)}
                    >
                      <Trans>Upgrade</Trans>
                    </RoundedThinButton>
                  )}
                  {hasPlan &&
                    this.props.plans[domain].period !== 'lifetime' &&
                    !this.props.plans[domain].cancelling &&
                    !this.isWix() &&
                    this.props.account &&
                    this.props.account.ref !==
                      'dealfuel'(
                        <IconButton
                          tooltip={t('Cancel Subscription')}
                          onClick={() => this.handleUnsubscribe(plan, domain)}
                        >
                          <Close />
                        </IconButton>,
                      )}
                </div>
              </React.Fragment>
            ) : (
              <AppsumoSubscriptions
                appsumoPlans={this.props.plans}
                appsumoBilling={this.props.appsumoBilling}
                domain={domain}
                handleSubscribe={domain => this.handleSubscribe(domain)}
              />
            )}
          </Thing>
          <DiyPlansInfo
            appname={domain}
            onCancelPlan={this.props.onCancelPlan}
          />
        </React.Fragment>
      );
    });

  render() {
    const accountApps = this.renderApps();

    return <div className="plans-container">{accountApps}</div>;
  }
}

export default withTranslation()(Subscriptions);
